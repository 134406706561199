body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Menonaktifkan pengguliran */
}


.full-screen-bg {
  background-image: url('/bg/bg2.jpg'); /* Path relatif ke folder public atau src */
  background-size: cover; /* Agar gambar menutupi seluruh layar */
  background-position: center; /* Menempatkan gambar di tengah */
  background-repeat: no-repeat; /* Cegah pengulangan gambar */
  height: 100%;
  width: 100%;
  position: fixed; /* Mengatur posisi tetap agar gambar tetap di latar belakang */
  top: 0;
  left: 0;
  z-index: -1; /* Menempatkan gambar di belakang konten */
  filter: brightness(50%); /* Mengurangi kecerahan gambar latar belakang */
}

.App {
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 600px;
  position: relative; /* Memastikan App memiliki positioning relatif untuk stacking context */
}

.search-bar input {
  padding: 10px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent; /* Membuat latar belakang transparan */
  color: white; /* Memastikan teks terlihat di atas latar belakang transparan */
}

.container {
  padding: 20px;
  border-radius: 10px;
 /*background-color: rgba(0, 0, 0, 0.6); */
}

.weather-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.today {
  text-align: center;
  margin-bottom: 20px;
  transition: padding 0.3s; /* Animasi transisi untuk padding */
}

.today.hidden-h1 {
  padding: 0; /* Sembunyikan padding jika tidak ada teks */
}

.today h1 {
  font-size: 2.5em;
  margin-bottom: 0px;
  color: white;
  font-family: Georgia;
  border: 0.5px solid #ccc; /* Tambahkan border */
  padding: 10px; 
  border-radius: 20px;
  font-weight: bold;
}

.today h2,
.today h3 {
  margin: 0; /* Hapus margin untuk h2 dan h3 */
}

.today h2 {
  font-size: 2.5em;
  color: white;
}

.today h3 {
  font-size: 1.5em;
  color: white;
}

.weather-info p {
  font-size: 1.2em;
  color: white; /* Warna teks suhu dan kondisi cuaca */
}

.forecast {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.day {
  text-align: center;
  flex: 1;
  background-color: rgb(0, 139, 182);
  border: 1px solid #ccc; /* Tambahkan border */
  border-radius: 20px; /* Opsional: Tambahkan border-radius untuk sudut yang melengkung */
  margin: 2px;
  padding: 10px; 
  color: white;
}

.day img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.day p {
  font-size: 1.2em;
  color: black; /* warna tulisan celcius cuaca harian */
}

.chart {
  width: 30%;
  position: fixed;
  right: 960px;
  bottom: 450px;
}

.error {
  color: red;
  margin-bottom: 20px;
}
